import {
    SET_ADULTS_DATA,
    SET_CHILDREN_DATA,
    SET_CHECKIN_DATE,
    SET_CHECKOUT_DATE,
    SET_PLACE_OR_HOTEL,
    LOADING_HOTEL_DATA,
    HOTEL_SEARCH_ID_RECEIVED,
    HOTEL_FORM_VISIBLE,
    HOTEL_INFOBOARD_VISIBLE,
    SET_HOTEL_SEARCH_ID,
    SET_HOTEL_DATA,
    HOTEL_RESULTS_IS_EMPTY,
    ERROR_MESSAGE_HOTEL_SEARCH_FORM,
    SET_HOTELS_LENGTH,
    SET_HOTEL_FILTER_DATA,
    SET_HOTEL_FILTERED_DATA,
    UPDATE_HOTEL_FILTERED_LENGTH,
    LENGTH_OF_STAY,
    SEARCH_HOTEL_STATUS,
} from "../type"

const initialState = { 
    cityOrHotelData: '',       
    checkInDate: '',
    checkOutDate: '',
    hotelAdults: 1,
    hotelChildren: [],
    lengthOfStay: 1,    
    loadingHotel: false,
    hotelInfoboardVisible: false,  
    hotelFormVisible: true,        
    hotelData: [],
    hotelFilteredData: [],    
    hotelResultIsEmpty: '',
    hotelsLength: 0,
    hotelsFilteredLength: 0,    
    hotelFilters: {
        // hotelType: [],
        // hotelPricePerNight: [],
        // hotelPricePerPeriod: [],
        // hotelAmenities: [],
        // hotelStars: [],
        // hotelDistanse: []
    },
    searchHotelStatus: false    
}

export default function (state = initialState, action) { 
    switch ( action.type) {
        case SET_ADULTS_DATA: {
            return {
                ...state,
                hotelAdults: action.payload
            }            
        }
        case SET_CHILDREN_DATA: {
            return {
                ...state,
                hotelChildren: action.payload
            }            
        }
        case SET_CHECKIN_DATE: {
            return {
                ...state, 
                checkInDate: action.payload                
            }
        }
        case SET_CHECKOUT_DATE: {
            return {
                ...state,
                checkOutDate: action.payload
            }
        }
        case  SET_PLACE_OR_HOTEL: {
            return {
                ...state,
                cityOrHotelData: action.payload
            }
        }
        // продолжительность проживания
        case  LENGTH_OF_STAY: {
            return {
                ...state,
                lengthOfStay: action.payload
            }
        }
        // соообщение об ошибках заполнения поисковой формы
        case ERROR_MESSAGE_HOTEL_SEARCH_FORM: {
            return {
                ...state,
                errorHotelMessage: action.payload
            } 
        }
        //предожения по отелям
        case LOADING_HOTEL_DATA: {
            return {
                ...state,
                loadingHotel: action.payload
            } 
        }
        //set hotel_search_id     
        case SET_HOTEL_SEARCH_ID: {
            return {
                ...state,
                hotelSearchId: action.payload
            }
        }
        //время получения hotel_search_id     
        case HOTEL_SEARCH_ID_RECEIVED: {
            return {
                ...state,
                timeOfReceiptHotelSearchId: action.payload
            }
        }
        // пустой результат поиска
        case HOTEL_RESULTS_IS_EMPTY: {
            return {
                ...state,
                hotelResultIsEmpty: action.payload
            }
        }
        // статус поиска отелей        
        case SEARCH_HOTEL_STATUS: {
            return {
                ...state,
                searchHotelStatus: action.payload
            }
        }
        // скрыть основную форму
        case HOTEL_INFOBOARD_VISIBLE: {
            return {
                ...state,
                hotelInfoboardVisible: action.payload
            }
        }
        // показать инфобокс
        case HOTEL_FORM_VISIBLE: {
            return {
                ...state,
                hotelFormVisible: action.payload
            }
        }
        //массив с отелями
        case SET_HOTEL_DATA: {
            return {
                ...state,
                hotelData: action.payload
            }
        }
        //копия массива с отелями
        case SET_HOTEL_FILTERED_DATA: {
            return {
                ...state,
                hotelFilteredData: action.payload
            }
        }
        //длина массива с отелями
        case SET_HOTELS_LENGTH: {
            //console.log('SET_HOTELS_LENGTH', action.payload)
            return {
                ...state,
                hotelsLength: action.payload
            }
        }
        //обновление отфильтрованного массива отелей
         case UPDATE_HOTEL_FILTERED_LENGTH: {
            return {
                ...state,
                hotelsFilteredLength: action.payload
            }
        }
        //задаем данные фильтра
        case SET_HOTEL_FILTER_DATA: {
            //console.log('SET_HOTEL_FILTER_DATA', action.payload)
            return {
                ...state,
                hotelFilters: action.payload
            }
            
        }
        default: return state
    }
}