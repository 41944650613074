import React from 'react'
//redux
import { Provider } from 'react-redux'
import store from './redux/store'
import Header from './component/layout/Header'
import Footer from './component/layout/Footer'
import { SearchContainer } from './container/SearchContainer'

function App() {
  return (    
    <Provider store = { store }>   
      <Header/>    
          <SearchContainer/>
      <Footer/>      
    </Provider>     
  )
}
export default App
