import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//forms
import { SimpleSearchForm } from '../component/avia/SimpleSearchForm'
import { searchFormType } from '../redux/actions/uiActions'
//переключение формы-табло
import { toggleForm } from '../redux/actions/dataActions'
//convert Date
import { formatDateTopForm } from '../function/formatDate'
// результаты поиска
import SearchResult from '../component/avia/SearchResult'
//filter
import Filter from '../component/avia/Filter'
import FilterSkeleton from '../component/UI/FilterSkeleton'
//modal window
import PureModal from 'react-pure-modal'
import 'react-pure-modal/dist/react-pure-modal.min.css'
//translate
import { translate } from '../function/translate'

export const AviaContainer = () => {

    const dispatch = useDispatch() 
    
    const {routeType, oneway, language } = useSelector(state => state.UI)    
    const passData = useSelector(state => state.aviaParams.passData)
    const formData = useSelector(state => state.aviaParams.simpleFormParams.segments)

    const {      
        formVisible, 
        infoboardVisible,      
        loading,
        resultIsEmpty,
        errorMessage = [],
        ticketsLength,
        searchStatus
    } = useSelector(state => state.aviaParams)   

    const typeHandler = event => {  
        const type = event.target.value
        dispatch(searchFormType(type))     
    }

    const handlerVisibleForm = e => {
        const data = !formVisible
        dispatch(toggleForm(data))
    }

    const modal = useRef() 
      
    return (
            <div>           
                <div>
                {infoboardVisible &&     
                    <div style={{width: "100%"}} onClick={handlerVisibleForm}>
                        <div className="searchFormTop">
                            <div className="col-md-12" style={{paddingLeft:0}}> 
                                <div className="searchFormTopText">
                                <span className="searchIcon"></span>
                                <span style={{paddingRight: 5, paddingLeft: 35}}>{ formData.origin ? formData.origin.name : '' }</span>
                                <span> ({ formData.origin ? formData.origin.code : '' })</span>
                                <span> - { formData.destination ? formData.destination.name : '' }</span>
                                <span style={{paddingRight: 5}}> ({ formData.destination ? formData.destination.code : '' })</span>
                                |
                                <span style={{paddingLeft: 5, color: '#fff'}}>
                                    <span>{ formData.date ? formatDateTopForm(formData.date) : '' }</span>
                                    <span>{ formData.combackDate ? ' - ' + formatDateTopForm(formData.combackDate) : '' } </span> 
                                    |
                                    <span style={{paddingLeft: 5}}> 
                                        { passData 
                                            ? passData.adults + passData.child + passData.baby
                                            : ''
                                        } {translate('pass', language) }
                                    </span>
                                </span>
                                { loading && searchStatus === true &&
                                    <span className="smSpinerContainer">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </span>
                                }
                                { ticketsLength !== 0 && 
                                    <span style={{paddingLeft: 25}}> <strong>{ ticketsLength } {translate('options', language) }</strong></span>
                                }
                                </div>
                            </div> 
                        </div> 
                    </div>
                }   
                { formVisible &&
                    <form className="searchFormHeader" onChange={typeHandler} >                
                        <div className="form-check form-check-inline">
                            <input 
                                className="form-check-input" 
                                type="radio" 
                                name="inlineRadioOptions"                         
                                value="round"                        
                                defaultChecked={routeType === 'round'}    
                            />
                            <label className="form-check-label" htmlFor="inlineRadio1">{translate('round_trip', language) }</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input 
                                className="form-check-input" 
                                type="radio" 
                                name="inlineRadioOptions"                        
                                value="oneway"                        
                                defaultChecked={ routeType === 'oneway'}                   
                            />
                            <label className="form-check-label" htmlFor="inlineRadio1">{translate('one_way', language) }</label>
                        </div>                            
                        { <SimpleSearchForm props = {oneway} />}          
                    </form>                 
                }  
            </div>
            { loading && ticketsLength === 0 &&
                <div className="col-12" style={{paddingTop: 16, textAlign: "center", minHeight: 50}}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            <div className="row" style={{marginTop: 10}}>
                { resultIsEmpty && 
                    <div className="col-12">
                        <div style={{textAlign: "center"}} className="alert alert-primary" role="alert">
                            Ничего на найдено. Попробуйте изменить даты
                        </div>
                    </div>                      
                }
                { errorMessage && errorMessage !=='' &&
                    <div className="col-12">
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    </div>
                }                     
                <div className="col-md-3 col-lg-3 col-xl-3 d-none d-sm-none d-md-block" style={{paddingRight: 0}}>
                    { loading === true &&  ticketsLength === 0 
                        ?  <FilterSkeleton/>  
                        :  <Filter/>
                    }
                </div> 
                { ticketsLength !== 0 &&
                    <>
                    <div className="col-12 col-sm-12 d-md-none d-lg-none d-xs-none d-sm-block d-block" style={{margin: "7px 0 12px 0"}}>
                        <button 
                            className="btn btn-success" 
                            onClick={() => modal.current.open()}                           
                            style={{width: "100%"}}
                        >
                        Фильтры
                        </button>    
                        <PureModal
                            header=""                              
                            onClose={() => {                    
                                return true;
                            }}               
                            ref={modal}
                            width="350px"
                            >
                            <Filter/>
                        </PureModal>
                    </div>                      
                    </>                        
                }
                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
                    <SearchResult/>      
                </div>
            </div> 
        </div>
    )
}
