export const hotelInternalType = (ln, id) => {

    const wordObject = {
        en: {
            1: "Standard room",
            2: "Superior room",
            3: "Triple room",
            4: "Deluxe room",
            5: "Studio",
            6: "Economy room",
            7: "Junior suite",
            8: "Apartment",
            9: "Suite",
            10: "Comfort room",
            11: "Executive room",
            12: "Family room",
            13: "Quadruple room",
            14: "Deluxe apartment",
            15: "Business room",
            16: "Club room",
            17: "Villa",
            18: "Bungalow",
            19: "Premium room",
            20: "Superior suite",
            21: "Studio suite",
            22: "Cottage",
            23: "Premier room",
            24: "Deluxe triple room",
            25: "Executive suite",
            26: "Deluxe villa",
            27: "Luxury room",
            28: "Deluxe bungalow",
            29: "Junior suite apartment",
            30: "Deluxe family room",
            31: "Dorm",
            32: "Superior single room",
            33: "Single deluxe room",
            34: "Deluxe king room",
            35: "Single room",
            36: "Single suite",
            37: "Single junior suite",
            38: "Family suite",
            39: "Deluxe suite",
            40: "President suite",
            41: "Deluxe cottage",
            42: "Premium suite",
            43: "Super deluxe room",
            44: "Loft",
            45: "Superior triple room",
            46: "Superior cottage",
            47: "Suite apartment",
            48: "Deluxe studio",
            49: "Superior studio",
            50: "Superior quadruple room",
            51: "Superior apartment",
            52: "Executive apartment",
            54: "Superior bungalow",
            55: "Deluxe dorm",
            56: "Superior dorm",
            57: "Super suite",
            58: "Economy quadruple room",
            59: "Executive family room",
            60: "Economy apartment",
            61: "Superior family room",
            62: "Comfort apartment",
            64: "Single economy room",
            65: "Single executive room",
            66: "Single comfort room",
            67: "Premium apartment",
            68: "Superior deluxe room",
            69: "Single executive suite",
            70: "Single business room",
            71: "Vip room",
            72: "Business suite",
            73: "Business apartment",
            74: "Economy triple room",
            75: "King room",
            76: "President room",
            77: "Premier apartment",
            78: "Premier suite",
            79: "Business superior room",
            80: "President villa",
            81: "Economy family room",
            82: "President junior suite",
            83: "Club apartment",
            84: "Superior queen room",
            85: "Executive club room",
            86: "Business deluxe room",
            87: "Business executive room",
            88: "President apartment",
            89: "Vip apartment",
            90: "King apartment",
            91: "President suite villa",
            92: "Executive suite apartment",
            128: "Executive king room",
            129: "King executive suite",
            130: "King junior suite",
            131: "King suite",
            132: "Superior king room",
            133: "Superior villa",
            134: "Suite villa",
            135: "King villa",
            136: "Executive villa",
            137: "Executive deluxe room",
            138: "Club deluxe room",
            139: "Club suite",
            140: "Privilege room",
            141: "Chalet",
            142: "Superior junior suite",
            143: "Deluxe junior suite",
            144: "Premier studio",
            145: "Executive junior suite",
            146: "Executive studio",
            147: "Quintuple room",
            148: "Deluxe chalet",
            149: "Superior executive room",
            150: "Luxury single room",
            151: "Single club room",
            152: "Family comfort room",
            153: "Superior club room",
            154: "House",
            155: "Premium Deluxe room",
            156: "Executive triple room",
            157: "Family Junior Suite",
            158: "Sextuple room",
            159: "Economy Sextuple room",
            161: "Luxury Villa",
            162: "Premium triple room",
            163: "Premium single room",
            164: "Economy quintuple room",
            165: "Luxury apartment",
            166: "Comfort studio",
            167: "Comfort suite"
        },
        ru: {
            1: "Стандартный номер",
            2: "Улучшенный номер",
            3: "Трехместный номер",
            4: "Номер Делюкс",
            5: "Студия",
            6: "Номер Эконом",
            7: "Номер Полулюкс",
            8: "Апартаменты",
            9: "Номер Люкс",
            10: "Номер Комфорт",
            11: "Представительский номер",
            12: "Семейный номер",
            13: "Четырехместный номер",
            14: "Делюкс апартаменты",
            15: "Номер Бизнес",
            16: "Клубный номер",
            17: "Вилла",
            18: "Бунгало",
            19: "Номер Премиум",
            20: "Улучшенный люкс",
            21: "Студия люкс",
            22: "Коттедж",
            23: "Номер Премьер",
            24: "Трехместный номер Делюкс",
            25: "Представительский номер Люкс",
            26: "Делюкс вилла",
            27: "Роскошный номер",
            28: "Делюкс бунгало",
            29: "Полулюкс апартаменты",
            30: "Семейный номер Делюкс",
            31: "Общая комната",
            32: "Улучшенный одноместный номер",
            33: "Одноместный номер Делюкс",
            34: "Королевский номер Делюкс",
            35: "Одноместный номер",
            36: "Одноместный номер Люкс",
            37: "Одноместный номер Полулюкс",
            38: "Семейный номер Люкс",
            39: "Люкс делюкс",
            40: "Президентский номер Люкс",
            41: "Делюкс коттедж",
            42: "Премиум номер Люкс",
            43: "Супер номер Делюкс",
            44: "Лофт",
            45: "Улучшенный трехместный номер",
            46: "Улучшенный коттедж",
            47: "Люкс апаратаменты",
            48: "Делюкс студия",
            49: "Улучшенная студия",
            50: "Улучшенный четырехместный номер",
            51: "Улучшенные апартаменты",
            52: "Представительские апартаменты",
            54: "Улучшенное бунгало",
            55: "Общая комната Делюкс",
            56: "Улучшенная общая комната",
            57: "Супер люкс",
            58: "Четырехместный номер Эконом",
            59: "Представительский семейный номер",
            60: "Эконом апартаменты",
            61: "Улучшенный семейный номер",
            62: "Апартаменты повышенной комфортности",
            64: "Одноместный номер Эконом",
            65: "Одноместный представительский номер",
            66: "Одноместный номер Комфорт",
            67: "Премиум апартаменты",
            68: "Улучшенный номер Делюкс",
            69: "Одноместный представительский номер Люкс",
            70: "Одноместный номер Бизнес",
            71: "Номер Vip",
            72: "Номер Бизнес-Люкс",
            73: "Бизнес апартаменты",
            74: "Трехместный номер Эконом",
            75: "Королевский номер",
            76: "Президентский номер",
            77: "Апартаменты Премьер",
            78: "Премьер номер Люкс",
            79: "Улучшенный номер Бизнес",
            80: "Президентская вилла",
            81: "Семейный номер Эконом",
            82: "Президентский номер Полулюкс",
            83: "Клубные апартаменты",
            84: "Улучшенный королевский номер",
            85: "Представительский клубный номер",
            86: "Бизнес номер Делюкс",
            87: "Представительский номер Бизнес",
            88: "Президентские апартаменты",
            89: "Vip апартаменты",
            90: "Королевские апартаменты",
            91: "Президентская люкс вилла",
            92: "Executive suite apartment",
            128: "Королевский представительский номер",
            129: "Королевский представительский номер Люкс",
            130: "Королевский номер Полулюкс",
            131: "Королевский номер Люкс",
            132: "Улучшенный королевский номер",
            133: "Улучшенная вилла",
            134: "Вилла Люкс",
            135: "Королевская вилла",
            136: "Представительская вилла",
            137: "Представительский номер Делюкс",
            138: "Клубный номер Делюкс",
            139: "Клубный номер Люкс",
            140: "Привилегированный номер",
            141: "Шале",
            142: "Улучшенный номер Полулюкс",
            143: "Полулюкс делюкс",
            144: "Студия Премьер",
            145: "Представительский номер Полулюкс",
            146: "Представительская студия",
            147: "Пятиместный номер",
            148: "Делюкс шале",
            149: "Улучшенный представительский номер",
            150: "Роскошный одноместный номер",
            151: "Одноместный клубный номер",
            152: "Семейный номер Комфорт",
            153: "Улучшенный клубный номер",
            154: "Дом",
            155: "Премиум номер Делюкс",
            156: "Трехместный представительский номер",
            157: "Семейный полулюкс",
            158: "Шестиместный номер",
            159: "Шестиместный номер Эконом",
            161: "Роскошная вилла",
            162: "Трехместный номер Премиум",
            163: "Одноместный номер Премиум",
            164: "Economy quintuple room",
            165: "Luxury apartment",
            166: "Comfort studio",
            167: "Comfort suite"
        }
    }

    const translate = wordObject[ln][id]
    return translate
}
