import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPassData } from '../../redux/actions/aviaActions'

export const ClassBox = () => {

    const [ dropdown, setDropdown ] = useState('dropdown')
    const [ dropdownMenu, setDropdownMenu ] = useState('dropdown-menu')
    const [ menuStatus, setMenuStatus ] = useState(false)
    const [ tripClass, setTripClass ] = useState('Y') 
    const { adults, child, baby } = useSelector(state => state.aviaParams.passData)

    const dispatch = useDispatch()     

    useEffect(() => {
        if(localStorage.hasOwnProperty('passData')){
            let passData = localStorage.getItem('passData')
            let passDataObj = JSON.parse(passData)
            dispatch(setPassData('tripClass', passDataObj.tripClass))
            dispatch(setPassData('adults', passDataObj.adults))
            dispatch(setPassData('baby', passDataObj.baby))
            dispatch(setPassData('child', passDataObj.child))                
        } 
    }, [])


    // меню с пассажирами
    const menuHandler = event => {       
        if(menuStatus === false){           
            setMenuStatus(true)             
            setDropdown('dropdown show')
            setDropdownMenu('dropdown-menu show')
        } else {            
            setMenuStatus(false)                
            setDropdown('dropdown')
            setDropdownMenu('dropdown-menu')
        }
    }

    // закрыть меню с классом и составом пассажиров (по крестику)
    const passMenuClick = event => {       
        setMenuStatus(false)
        setDropdown('dropdown')
        setDropdownMenu('dropdown-menu')               
    } 

    //расшифровка класса    
    const classText = (data) => {
        if(data==='Y'){
            return "эконом"
        } else {
            return "бизнес"
        }
    } 

    //+- button
    const handlerCounter = (e, name, value) => {             
        e.preventDefault()        
        if(name==='adults-minus') {               
            dispatch(setPassData('adults', value))
        } else if(name==='adults-plus') {           
            dispatch(setPassData('adults', value))
        } else if(name==='child-minus') {           
            dispatch(setPassData('child', child-1))
        } else if(name==='child-plus') {           
            dispatch(setPassData('child', child+1))
        } else if(name==='baby-minus') {           
            dispatch(setPassData('baby', baby-1))    
        } else if(name==='baby-plus') {           
            dispatch(setPassData('baby', baby+1))
        }
    }

    // changeClass
    const changeHandler = event => {    
        const name = event.target.name   
        const value =  event.target.value 
        dispatch(setPassData(name, value))
    }

    return (
            <div className="form-group">
            <label htmlFor="class_pass" style={{color: "#fff"}}>Класс и пассажиры</label>
            <div className={dropdown}>
                <button 
                    className="btn btn-secondary dropdown-toggle" 
                    type="button" id="dropdownMenuButton" 
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"
                    onClick={menuHandler}
                    style={{width: "100%", color: "#6c757d", background: "#fff", textAlign: "left", borderRadius: "0px !important"}}
                >
                {parseInt(adults) + parseInt(child) + parseInt(baby)} пасс., {classText(tripClass)}
                </button> 
                <div className={dropdownMenu} aria-labelledby="dropdownMenuButton"> 
                    <button 
                        type="button" 
                        className="close" 
                        data-dismiss="alert" 
                        aria-label="Close"
                        style={{color: "#02122c"}}
                        onClick={passMenuClick}
                        >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="form-group">
                        <p style={{margin: "0 0 9px 0", fontWeight: "600"}}>Класс</p>
                        <select 
                            className="form-control"                            
                            name="tripClass" 
                            defaultValue={tripClass}
                            onChange={changeHandler}>
                            <option value="Y">Эконом</option>
                            <option value="C">Бизнес</option>                                            
                        </select>
                    </div>
                    <div className="counter-wrapper">    
                        <p className="counter-button-block-name">Взрослые </p>
                        <div className="counter-block">                       
                        <button 
                            disabled={ adults <=1 ? true : false } 
                            className="counter-button" 
                            onClick={e => handlerCounter(e, 'adults-minus', adults-1)}
                        >
                                <div className="counter-button-text-container">
                                    <div className="counter-button-text-container-text">
                                        -
                                    </div>
                                </div>
                        </button>
                        <div className="counter-value">
                                {adults}
                        </div>
                        <button 
                            disabled={ adults >=7 ? true : false } 
                            className="counter-button" 
                            onClick={e => handlerCounter(e, 'adults-plus', adults+1)}>
                                <div className="counter-button-text-container">
                                    <div className="counter-button-text-container-text">
                                        +
                                    </div>
                                </div>
                        </button>
                        </div>   
                    </div>
                    <div className="counter-wrapper" style={{marginTop: 5}}>
                        <p className="counter-button-block-name">Дети <span className="counter-value-name">2-11</span></p>
                        <div className="counter-block">                    
                        <button 
                            disabled={ child <=0 ? true : false }
                            className="counter-button" 
                            onClick={e => handlerCounter(e, 'child-minus', child-1)}>
                                <div className="counter-button-text-container">
                                    <div className="counter-button-text-container-text">
                                        -
                                    </div>
                                </div>
                        </button>
                        <div className="counter-value">
                                {child}
                        </div>
                        <button 
                            disabled={ child >=7 ? true : false }
                            className="counter-button" 
                            onClick={e => handlerCounter(e, 'child-plus', child+1)}>
                                <div className="counter-button-text-container">
                                    <div className="counter-button-text-container-text">
                                        +
                                    </div>
                                </div>
                        </button>
                        </div>
                    </div> 
                    <div className="counter-wrapper" style={{marginTop: 5}}>
                        <p className="counter-button-block-name">Младенцы <span className="counter-value-name">0-2</span></p>               
                        <div className="counter-block">                        
                        <button 
                            disabled={ baby <=0 ? true : false }
                            className="counter-button" 
                            onClick={e => handlerCounter(e, 'baby-minus', baby-1)}>
                                <div className="counter-button-text-container">
                                    <div className="counter-button-text-container-text">
                                        -
                                    </div>
                                </div>
                        </button>
                        <div className="counter-value">
                                {baby}
                        </div>
                        <button 
                            disabled={ baby >=7 ? true : false }
                            className="counter-button" 
                            onClick={e => handlerCounter(e, 'baby-plus', baby+1)}>
                                <div className="counter-button-text-container">
                                    <div className="counter-button-text-container-text">
                                        +
                                    </div>
                                </div>
                        </button>
                        </div> 
                    </div>
                </div>                            
            </div>
        </div>
    )
}
