import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { changeFilters } from '../../redux/actions/aviaActions'

class Checkbox extends Component {

    constructor(props) {
        super(props)
        this.state = {         
            allChecked: true,           
            limit: 5,            
            BtnStyle: {display: 'block', marginTop: '10px'}
        }
    }

    //показать все
    showAll = (e) => {
        e.stopPropagation()
        this.setState({
            limit: 15,
            BtnStyle: {display: 'none', marginTop: '10px'}
        })
    }  

    checkBoxHandler = (e, name, onlyChecked)  => {
        e.stopPropagation()
        this.setState({ allChecked: false })
        this.props.changeFilters(name, onlyChecked)
    }
   
    // выбор всех
    allCheckBoxHandler = (e, name)  => {          
        //console.log('allCheckBoxHandler', name )  
        const onlyChecked = 'all'      
        this.props.changeFilters(name, onlyChecked)       
        this.setState({
            allChecked: !this.state.allChecked,
            onlyChecked: false
       })
    }

    render() {         
        return (
            <div className="sidebar_block">
                <h4>{this.props.checkBoxTitle}</h4> 
                <div 
                    className="custom_checkbox"                   
                    onClick={ e=> this.allCheckBoxHandler (e, this.props.checkBoxPrefix + '_all')} 
                >
                    <input type="checkbox" checked={this.state.allChecked} onChange={e => {}}/>
                    <label 
                        className="g-text-overflow" 
                        htmlFor={this.props.checkBoxPrefix + '_all'}
                        >
                        Все
                    </label>
                </div>
                { this.props.data.slice(0, this.state.limit).map((item, index)=> ( 
                        <div                           
                            className="custom_checkbox" 
                            key={index}                         
                            onClick = { e => this.checkBoxHandler(e, this.props.checkBoxPrefix +'_' + item.value, false)}                                                    
                        >
                        <input type="checkbox" checked={item.status} onChange={e => {}}/>                        
                        <label className="g-text-overflow" htmlFor={this.props.checkBoxPrefix +'_' + item.value}>
                                { this.props.subData 
                                ? this.props.filters.arrayAirlinesName[index]                                        
                                : this.props.handler(item.value)}
                        </label>                        
                        <div 
                            className="only-text"                           
                            onClick = { e => this.checkBoxHandler(e, this.props.checkBoxPrefix +'_' + item.value, true)}      
                        >
                            только
                        </div>                        
                        <div>
                            { index === 4 && 
                                <button 
                                    type="button" 
                                    className="btn btn-info btn-sm"
                                    onClick={e => this.showAll(e)}
                                    style={this.state.BtnStyle}
                                >
                                    Показать все
                                </button> 
                            }
                        </div>


                    </div>
                    ))                                
                }
            </div>
        )
    }
}

Checkbox.propTypes = {
    filters: PropTypes.object,
    filterBuild: PropTypes.func,
    changeFilters: PropTypes.func
}

const mapStateToProps = state => ({
    filters: state.aviaParams.filters,
    oneway: state.UI.oneway,
    tickets: state.aviaParams.tickets,
    combackDate: state.aviaParams.simpleFormParams.segments.combackDate
})


const mapDispatchToProps = {changeFilters}
export default connect(mapStateToProps, mapDispatchToProps)(Checkbox)


// import React, { useState, useEffect } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { changeFilters } from '../../redux/actions/aviaActions'

// const Checkbox = ({checkBoxTitle, checkBoxPrefix, handler, subData, data, filterPrefix}) => {

//     const [allChecked, setAllChecked] = useState(true)
//     const [limit, setLimit] = useState(5)
//     const [BtnStyle, setBtnStyle] = useState({display: 'block', marginTop: '10px'})
    

//     const dispatch = useDispatch()
//     // данные для фильтра в зависимости от его типа 
//     const filters = useSelector(state => state.aviaParams.filters)
    
//     const [filtersData, setFiltersData] = useState()
//     //console.log(filterPrefix, filtersData)
    
//     useEffect(() => {
//         setFiltersData(data)       
//     }, [])   
        
   
//     const showAll = (e) => {
//         e.stopPropagation()
//         setLimit(15)
//         setBtnStyle({display: 'none', marginTop: '10px'})
//     } 

//     const checkBoxHandler = (e, name, onlyChecked)  => {
//         e.stopPropagation()
//         setAllChecked(false) 
//         console.log('name', name)      
//         dispatch(changeFilters(name, onlyChecked))
//     }

//     const allCheckBoxHandler = (e, name)  => {
//         const onlyChecked = 'all'      
//         dispatch(changeFilters(name, onlyChecked))    
//         setAllChecked(!allChecked)
//     }   

//     return (
//         <div className="sidebar_block">
//         <h4>{checkBoxTitle}</h4> 
//         <div 
//             className="custom_checkbox"                   
//             onClick={ e=> allCheckBoxHandler (e, checkBoxPrefix + '_all')} 
//         >
//             <input type="checkbox" checked={allChecked} onChange={e => {}}/>
//             <label 
//                 className="g-text-overflow" 
//                 htmlFor={checkBoxPrefix + '_all'}
//                 >
//                 Все
//             </label>
//         </div>
//         { filters[filterPrefix].slice(0, limit).map((item, index)=> (
//                 <div                           
//                     className="custom_checkbox" 
//                     key={index}                         
//                     onClick = { e => checkBoxHandler(e, checkBoxPrefix +'_' + item.value, false)}                                                    
//                 >
//                 <input type="checkbox" checked={item.status} onChange={() => {}}/>
                
//                 <label className="g-text-overflow" htmlFor={checkBoxPrefix +'_' + item.value}>
//                         { subData 
//                         ? filters.arrayAirlinesName[index]                                        
//                         : handler(item.value)}
//                 </label>   
//                 <div 
//                     className="only-text"                           
//                     onClick = { e => checkBoxHandler(e, checkBoxPrefix +'_' + item.value, true)}      
//                 >
//                     только
//                 </div>                        
//                 <div>
//                     { index === 4 && 
//                         <button 
//                             type="button" 
//                             className="btn btn-info btn-sm"
//                             onClick={e => showAll(e)}
//                             style={BtnStyle}
//                         >
//                             Показать все
//                         </button> 
//                     }
//                 </div>
//             </div>
//             ))                                
//         }
//     </div>
//     )
// }

// export default Checkbox
