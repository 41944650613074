import {
    AVIA_HOTEL_FORM_VISIBLE,
    AVIA_HOTEL_INFOBOARD_VISIBLE
} from "../type"

const  initialState = { 
    aviaHotelInfoboardVisible: false,  
    aviaHotelFormVisible: true     
}

export default function (state = initialState, action) { 
    switch ( action.type) {
        // скрыть основную форму
        case AVIA_HOTEL_FORM_VISIBLE: {
            return {
                ...state,
                aviaHotelFormVisible: action.payload
            }
        }    
         // показать инфобокс
        case AVIA_HOTEL_INFOBOARD_VISIBLE: {
            return {
                ...state,
                aviaHotelInfoboardVisible: action.payload
            }
        } 
        default: return state 
    }
}
