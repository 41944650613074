import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { preHotelSearch } from '../../redux/actions/dataActions'
import HotelListTemplate from '../hotel/HotelListTemplate'
import HotelListSkeleton from '../hotel/HotelListSkeleton'

class HotelSearchResult extends Component {

   constructor(props) {
       super(props)
        this.state = {
            hotelsLength: 0,
            i: 1,
            limit: 10,
            hotels_limit: 10,
            btnMoreStyle: {width: "100%", marginBottom: "15px", display: "block"}
        }
   } 

   //вывод отелей порциями
   moreHotels = event => {            
        const i = this.state.i        
        this.setState({
            i: i+1           
        })
        const hotels_limit = 10 + i*this.state.limit          
        this.setState({
            hotels_limit: hotels_limit
        }) 
        if(hotels_limit > this.props.hotelsLength) {
            this.setState({
                btnMoreStyle: {width: "100%", marginBottom: "15px", display: "none"}
            })
        }       
    }

    // обновить поиск
    refreshSearch  = event => {
        event.preventDefault()
        this.props.preHotelSearch()
    }


    render() {
        
        return (
            <div>
                {this.props.hotelSearchId === '' &&                       
                    <div style={{textAlign: "center"}} className="alert alert-warning" role="alert">
                        Увы, но данные устарели
                        <button 
                            type="button" 
                            className="btn btn-link" 
                            data-toggle="button" 
                            aria-pressed="false"
                            onClick = { this.refreshSearch }
                        >
                            Обновить поиск
                        </button>
                    </div>                        
                }
                { this.props.hotelsFilteredLength === 0 && this.props.hotelsLength > 0 &&
                    <div style={{textAlign: "center"}} className="alert alert-success" role="alert">
                        Вариантов не осталось, смягчите фильтры
                    </div>
                }
                { this.props.loadingHotel === true &&  this.props.hotelsLength === 0 &&
                    <HotelListSkeleton/>
                }
                { this.props.hotelFilteredData && this.props.hotelsLength > 0 &&
                    this.props.hotelFilteredData.filter((item, index) => index < this.state.hotels_limit).map(hotel => <HotelListTemplate key={hotel.id} hotel={hotel} />)                
                }
                { this.props.hotelsFilteredLength > 10 && this.props.searchHotelStatus === false && 
                    <button className="btn btn-info" style = { this.state.btnMoreStyle } onClick={this.moreHotels}>Показать еще</button>
                }
            </div>
        )
    }
}

HotelSearchResult.propTypes = {
    //hotelData: PropTypes.object,   
    //hotelFilteredData: PropTypes.object, 
    hotelsLength: PropTypes.number.isRequired,    
    loadingHotel: PropTypes.bool.isRequired,
    preHotelSearch: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    hotelData: state.hotelParams.hotelData,
    hotelFilteredData: state.hotelParams.hotelFilteredData,
    hotelsLength: state.hotelParams.hotelsLength,
    hotelsFilteredLength: state.hotelParams.hotelsFilteredLength,
    searchHotelStatus: state.hotelParams.searchHotelStatus,
    hotelSearchId: state.hotelParams.hotelSearchId,
    loadingHotel: state.hotelParams.loadingHotel
})

const mapDispatchToProps = { preHotelSearch }

export default connect(mapStateToProps, mapDispatchToProps)(HotelSearchResult)
