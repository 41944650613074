import { createStore, combineReducers, applyMiddleware, compose } from "redux"
import thunk from 'redux-thunk'
//import dataReducer from '../redux/reducer/dataReducer'
import uiReducer from '../redux/reducer/uiReducer'
// new
import aviaReducer from '../redux/reducer/aviaReducer'
import hotelReducer from '../redux/reducer/hotelReducer'
import aviaHotelReducer from '../redux/reducer/aviaHotelReducer'

const initialState = {}

const middleware = [thunk]

const reducers = combineReducers({
    //data: dataReducer,
    UI: uiReducer,
    aviaParams: aviaReducer,
    hotelParams: hotelReducer,
    aviaHotelParams: aviaHotelReducer
})

const devTools = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : null

const store = createStore(   
    reducers,   
    initialState,    
    compose (
       applyMiddleware(...middleware),
       //devTools       
    )    
)

export default store
