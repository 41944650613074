// получение курса валют
export const FETCH_CURRENCIES = 'FETCH_CURRENCIES'
// change language
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
// change currency
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY'

// город вылета
export const UPDATE_ORIGIN_DATA = 'UPDATE_ORIGIN_DATA'
// город прибытия
export const UPDATE_DESTINATION_DATA = 'UPDATE_DESTINATION_DATA'
// дата вылета 
export const UPDATE_DATE = 'UPDATE_DATE'
// дата возвращения
export const UPDATE_COMBACK_DATE = 'UPDATE_COMBACK_DATE'
// данные о пассажирах 
export const UPDATE_PASS_DATA = 'UPDATE_PASS_DATA'
// обновление местоположения по IP
export const UPDATE_GEOIP = 'UPDATE_GEOIP'
// получаем search_id и записываем в стор
export const SET_SEARCH_ID = 'SET_SEARCH_ID'
// оригинальный массив билетов
export const SET_TICKETS = 'SET_TICKETS'
// отфильтрованный массив билетов
export const SET_FILTERED = 'SET_FILTERED'
// длина отфильтрованного массива
export const UPDATE_FILTERED_LENGTH = 'UPDATE_FILTERED_LENGTH'
// самый дешевый билет
export const SET_CHEAPETS_TICKET = 'SET_CHEAPETS_TICKET'
// самый быстрый билет
export const SET_FASTEST_TICKET = 'SET_FASTEST_TICKET'
// фильтры
export const SET_FILTER_DATA = 'SET_FILTER_DATA'
// время получения search_id
export const SEARCH_ID_RECEIVED = 'SEARCH_ID_RECEIVED'
// тип формы поиска
export const SEARCH_FORM_TYPE = 'SEARCH_FORM_TYPE'
// статус поиска
export const SEARCH_STATUS = 'SEARCH_STATUS'
// ничего не найдено
export const RESULT_IS_EMPTY = 'RESULT_IS_EMPTY'
// смягчите фильтры
export const FILTERED_ARRAY_LENGTH_STATUS = 'FILTERED_ARRAY_LENGTH_STATUS'
// сообщение об ошибках заполнения формы поиска
export const ERROR_MESSAGE_SEARCH_FORM = 'ERROR_MESSAGE_SEARCH_FORM'
// UI спинер загрузки авиа
export const LOADING_DATA = 'LOADING_DATA'
// UI видимость авиа инфо табло
export const INFOBOARD_VISIBLE = 'INFOBOARD_VISIBLE'
// UI видимость авиа формы поиска
export const FORM_VISIBLE = 'FORM_VISIBLE'


//** ОТЕЛИ*/
// взрослые
export const SET_ADULTS_DATA = 'SET_ADULTS_DATA'
// дети 
export const SET_CHILDREN_DATA = 'SET_CHILDREN_DATA'
// дата заселения
export const SET_CHECKIN_DATE = 'SET_CHECKIN_DATE'
// дата выселения
export const SET_CHECKOUT_DATE = 'SET_CHECKOUT_DATE'
// выбор города или отеля
export const SET_PLACE_OR_HOTEL = 'SET_PLACE_OR_HOTEL'
// продолжительность проживания
export const LENGTH_OF_STAY = 'LENGTH_OF_STAY'
// получен отельный search_id
export const HOTEL_SEARCH_ID_RECEIVED = 'HOTEL_SEARCH_ID_RECEIVED'
// записываем отельный search_id
export const SET_HOTEL_SEARCH_ID = 'SET_HOTEL_SEARCH_ID'
// массив отелей
export const SET_HOTEL_DATA = 'SET_HOTEL_DATA'
// отфильтрованный массив с отелями
export const SET_HOTEL_FILTERED_DATA = 'SET_HOTEL_FILTERED_DATA'
// сообщение, если массив отелей пустой
export const HOTEL_RESULTS_IS_EMPTY = 'HOTEL_RESULTS_IS_EMPTY'
// сообщение об ошибках заполнения формы поиска отелей
export const ERROR_MESSAGE_HOTEL_SEARCH_FORM = 'ERROR_MESSAGE_HOTEL_SEARCH_FORM'
//длина массива отелей
export const SET_HOTELS_LENGTH = 'SET_HOTELS_LENGTH'
// данные для фильтра
export const SET_HOTEL_FILTER_DATA = 'SET_HOTEL_FILTER_DATA'
// длина фильтрованного массива
export const UPDATE_HOTEL_FILTERED_LENGTH = 'UPDATE_HOTEL_FILTERED_LENGTH'
// статус поиска
export const SEARCH_HOTEL_STATUS = 'SEARCH_HOTEL_STATUS'
// UI спинер загрузки авиа
export const LOADING_HOTEL_DATA = 'LOADING_HOTEL_DATA'
// UI видимость отельной формы поиска
export const HOTEL_FORM_VISIBLE = 'HOTEL_FORM_VISIBLE'
// UI видимость отельного ифон табло
export const HOTEL_INFOBOARD_VISIBLE = 'HOTEL_INFOBOARD_VISIBLE'


//**avia+hotel */
// UI  видимость объединенной формы
export const AVIA_HOTEL_FORM_VISIBLE = 'AVIA_HOTEL_FORM_VISIBLE'
// UI  видимость объединенного инфотабло
export const AVIA_HOTEL_INFOBOARD_VISIBLE = 'AVIA_HOTEL_INFOBOARD_VISIBLE'