import React, { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { autocompleteOrigin, autocompleteDestination } from '../../redux/actions/aviaActions' 
import { autocompletePlaceOrHotel } from '../../redux/actions/dataActions' 
//автокомплит данных для поиска отелей
import { getHotelAutocomplete } from '../../function/hotelAutocomplete'

export const InputAutocomplete = ({city, label, placeholder, cityType, originError, destinaionError }) => {    
    
    const dispatch = useDispatch()
    const cityInput = useRef()
    const [userInput, setUserInput] = useState(city)
    const [cityData, setCityData] = useState([])    
    const [activeCity, setActiveCity] = useState(0)
    const [showCityList, setShowCityList] = useState(false)     

    useEffect(() => {
        if(cityType === 'origin') {
            cityInput.current.focus()
        } 
        setUserInput(city)  
    }, [city])

    // выбор всего в поле    
    const handleFocus = (event) => event.target.select()  

    const onChange = e => {
        const userInput = e.target.value
        setUserInput(userInput)       
        if(userInput.length>1) {
            fetch(`https://autocomplete.travelpayouts.com/places2?term=${userInput}&locale=ru&types[]=city`)
                .then(response=> response.json())
                .then(data => {
                    setActiveCity(0)                
                    setShowCityList(true)                      
                    setCityData(data) 
                })
                .catch(error => {
                    console.log(error)
                }) 
        }        
    }

    const onClick = (event, cityTitle, cityData) => { 
            setActiveCity(0) 
            setShowCityList(false)
            setUserInput (cityTitle)      
        if(cityType === 'origin') {          
            dispatch(autocompleteOrigin(JSON.parse(`${cityData}`))) 
        } else if(cityType === 'destination') {           
            dispatch(autocompleteDestination(JSON.parse(`${cityData}`)))              
            const cityDapartureIata = JSON.parse(`${cityData}`).code
            //устанавливаем данные для поиска отелей
            getHotelAutocomplete(cityDapartureIata)
            .then( response => { 
                const cityData = response.data.results.locations                
                // eslint-disable-next-line
                cityData.map(item => {
                    if(item.iata.includes(cityDapartureIata) === true){                                             
                        dispatch(autocompletePlaceOrHotel(item))
                        //localStorage.setItem('hotelData', JSON.stringify(item))                        
                    }                   
                })                
            })   
            .catch(error => {
                console.log(error)
            }) 
        }    
    }

    const onKeyDown = e => {            
        if (e.keyCode === 13) { 
            if(cityData[activeCity]) {
                const cityTitle = cityData[activeCity].name               
                setActiveCity(0) 
                setShowCityList(false)
                setUserInput (cityTitle)  
                if(cityType === 'origin') {          
                    dispatch(autocompleteOrigin(cityData[activeCity])) 
                } else if(this.props.cityType === 'destination') {           
                    dispatch(autocompleteDestination(cityData[activeCity]))
                } 
            }
        }   else if (e.keyCode === 38) {               
                if (activeCity === 0) {
                    return
                }      
                setActiveCity(activeCity - 1)               
        }   else if (e.keyCode === 40) {                
                if (activeCity - 1 === cityData.length) {
                    return
                }      
                setActiveCity(activeCity + 1) 
        }
    }
    
    let cityListComponent

    if(cityData && cityData.length>0 && showCityList) {
        cityListComponent = (
            <ul className="cityList" >
            {    // eslint-disable-next-line    
                cityData.map((item, index) => { 
                    if(index <= 4) {
                        let className
                        if (index === activeCity) {
                            className = "cityList-active";
                        }                  
                        return (                                  
                            <li className={className}
                                key = {index}                                   
                                name = {item.name}
                                value = {JSON.stringify(item)}
                                onClick = {e => onClick(e, item.name, JSON.stringify(item))} 
                            >
                            <span className="autocompleteCity">{item.name}</span> ({item.code}) <br/>
                            <span className="autocompleteCountry">{item.country_name}</span>
                            </li>
                        )   
                    } 
                })                                    
            }
            </ul>
        )
    }

    return (
        <>          
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <label htmlFor="city" className="searchFormLabel">{label}</label>
            {originError && <span style={{fontSize: 13, color: "yellow"}}>Укажите пункт вылета</span> }
            {destinaionError && <span style={{fontSize: 13, color: "yellow"}}>Укажите пункт назначения</span> }
        </div> 
        <input
            className="form-control" 
            autoComplete="off"
            placeholder={placeholder}
            type="text"
            onChange={onChange}
            onKeyDown={onKeyDown}
            onFocus={handleFocus}
            value={userInput}
            ref={cityInput}                
        />        
        {cityListComponent} 
        </>
    )
}
