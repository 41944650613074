import React, { Component } from 'react'
import ScrollToTop from 'react-scroll-up'

class Footer extends Component {    

    render() {
        const scroolStyle = {
            position: 'fixed',
            bottom: 7,
            right: 40,
            cursor: 'pointer',
            border: '2px solid #fff',
            transitionDuration: '0.2s',
            transitionTimingFunction: 'linear',
            transitionDelay: '0s',
            width: 50,
            height: 50,
            borderRadius: '50%',
            //background: `#ffc107 url(./img/scroll-top.png) no-repeat center`,
            background: '#ffc107',
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 2px 4px 0px'
        }

        return (
            <div className="col-md-12" style={{padding: 10}}>
                <div>
                    <ScrollToTop 
                        showUnder={160}
                        duration={500}
                        style={scroolStyle}
                    >           
                    </ScrollToTop>
                </div> 
                <div className="result-item">            
                    <div className="result-head" style={{background: '#e1e8ec', color: "#e1e8ec"}}>skeleton</div>
                    <div style={{padding: 15}}>            
                    <h1>Билетикофф - поиск дешевых авиабилетов онлайн</h1>
                    <p>Вы собираетесь в поездку и выбираете компанию, где купить авиабилеты онлайн? Глаза разбегаются 
                    от того широкого выбора, который сегодня предлагает интернет. Но раз вы оказались на сайте сервиса 
                    Билетикофф – значит, вы нашли именно то, что искали. Здесь вы сможете купить билеты на самолет 
                    в любом направлении по самой выгодной цене.</p>
                    <p>Наш сервис предоставляет возможность искать предложения от авиакомпаний и 
                    билетных агенств, позволяющих приобрести авиабилеты в тысячи городов, расположенных на различных континентах.</p>    
                    <p>Купить авиабилеты онлайн не сложнее, чем заказать пиццу. Просто заполните предлагаемые 
                    поля на сайте той авиакомпании или агентства, где вы решили купить билет. Сделайте это внимательно, 
                    особенно при заполнении данных паспорта и электронной карты, которой вы будете оплачивать свой заказ. 
                    При переводе денег вы будете перенаправлены автоматически в защищенную зону платежной системы.</p>
                    <p>На указанный вами e-mail авиакомпания вышлет электронный билет и квитанцию.
                     При посадке вам нужно будет только предъявить паспорт, но если у вас остались какие-то сомнения, 
                     то вы можете распечатать полученные по почте документы.</p>
                    <p>Не ищите лучшее, вы его уже нашли – Билетикофф не подведет вас, куда бы вы не летели!</p>
                    <h3>Условия предоставления услуги</h3>
                    <p>Мы не занимаемся непосредственной продажей авиабилетов, а предоставляем услугу поиска. 
                    Окончательный выбор остается за Вами. Процедура покупки происходит в защищенной зоне на сайте 
                    авиакомпании или билетного агенства. Мы не храним и не используем любую информацию, относящуюся к нашим пользователям.</p>          
                    </div>   
                    <p style={{textAlign: 'center'}}>Biletikoff 2020 © Copyright</p>
                </div>               
            </div>
        )
    }
}

export default Footer
