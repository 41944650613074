import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ClassBox } from './ClassBox'
import { InputAutocomplete } from '../avia/InputAutocomplete'
import DtPicker from '../avia/DtPicker'
import { geoIp, setStorageDataToForm, preSimpleSearch } from '../../redux/actions/aviaActions'

export const SimpleSearchForm = () => {

    const dispatch = useDispatch()

    const { routeType, oneway, currency, language } = useSelector(state => state.UI)
    const { geoData, currencies, proposals, passData, localStorageStatus } = useSelector(state => state.aviaParams)
    const formData = useSelector(state => state.aviaParams.simpleFormParams.segments)      

    const [ origin, setOrigin ] = useState()
    const [ destination, setDestination ] = useState() 
    const [ originError, setOriginError ] = useState(false)
    const [ destinaionError, setDestinationError] = useState(false)

    // если что то есть в localstorage
    useEffect(() => {
        dispatch(geoIp())
        if(!localStorage.hasOwnProperty('formData')) {
            setOrigin (geoData.name)              
        }
    }, [geoData.name])

    useEffect(() => {           
        if(localStorage.hasOwnProperty('formData')){             
            dispatch(setStorageDataToForm()) 
            const localStorageData =  JSON.parse(localStorage.getItem('formData')) 
            if(localStorageData.origin && localStorageData.origin.name){                
                setOrigin (localStorageData.origin.name)               
            }             
            if (localStorageData.destination && localStorageData.destination.name) {               
                setDestination(localStorageData.destination.name)
            }
        }
    }, [])

    const formSubmit = event => {       
        event.preventDefault()    
        localStorage.setItem('formData', JSON.stringify(formData))
        localStorage.setItem('passData', JSON.stringify(passData))   
        
        
        if (!formData.origin) {
            console.log('Не заполнен город вылета')
            setOriginError(true)            
        } else if (!formData.destination) {
            console.log('Не заполнен город прилета')
            setDestinationError(true)
            setOriginError(false)  
        } else {           
            setOriginError(false)
            setDestinationError(false)
            dispatch(preSimpleSearch())
        }        
    }

    return (             
            <div>
                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6"> 
                        <InputAutocomplete 
                            city = { origin ? origin : '' }                               
                            label = "Откуда"
                            placeholder = "Пункт вылета"
                            cityType = "origin"
                            originError = {originError}
                        />    
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6"> 
                        <InputAutocomplete 
                            city = { destination ? destination : '' }                               
                            label = "Куда"
                            placeholder = "Пункт назначения"
                            cityType = "destination"
                            destinaionError = {destinaionError}
                        />                        
                    </div>                  
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4">
                        <label htmlFor="date-from" className="searchFormLabel">Дата</label> 
                        <DtPicker placeholderText="Дата вылета" dateType="originDate" dtVisible={false} />                  
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4">
                        <label htmlFor="date-comback" className="searchFormLabel">Обратно</label>                       
                        {!oneway 
                        ? <DtPicker placeholderText="Дата возврата" dateType="combackDate"  dtVisible={oneway}/>
                        :
                        <input 
                            type="text"                        
                            className="form-control" 
                            autoComplete="off"
                            disabled={true}                              
                        />                        
                        }
                    </div>   
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4">
                        <ClassBox/> 
                    </div>
                </div> 
                <div className="row" style={{textAlign: "right", marginTop: 15}} > 
                    <div className="col-xl-8 col-lg-8"></div>      
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <button 
                            type="submit" 
                            onClick={formSubmit} 
                            className="btn btn-info" 
                            style={{width: "100%"}}>
                            Найти билеты
                        </button> 
                    </div>
                </div>                            
            </div>
    )
}
