import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrencyRate } from '../redux/actions/aviaActions'
import { AviaContainer } from '../container/AviaContainer'
import { HotelContainer } from '../container/HotelContainer'
import { translate } from  '../function/translate'

export const SearchContainer = () => {

    const dispatch = useDispatch()
    const language = useSelector(state => state.UI.language)

    const active = {               
        style: { background: '#036', color: '#fff' },
        showIndex: { display: 'block' }
    }

    const notActive = {               
        style: {background: '#fff', color: '#036'},
        showIndex: {display: 'none'}
    }

    const [tabAvia, setTabAviaStyle] = useState(active)
    const [tabHotel, setTabHotelStyle] = useState(notActive)

    useEffect(() => {        
        dispatch(getCurrencyRate())
    }, [])

    const handlerTab = (e, name) => {           
        if(name==='avia') {           
            setTabAviaStyle(active)
            setTabHotelStyle(notActive)           
        } else {
            setTabAviaStyle(notActive)
            setTabHotelStyle(active) 
        }
    }

    return ( 
        <div className="col-md-12" style={{padding: "0 15px 0 15px", minHeight: 500 }}>            
            <nav className="nav-top">
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <span                         
                        className="nav-item nav-link"
                        style={tabAvia.style}                        
                        onClick={e => handlerTab(e, 'avia')}
                        >
                        {translate('aviatickets', language) }
                    </span>
                    {/* <span 
                        className="nav-item nav-link"  
                        style={tabHotel.style}
                        onClick={e => handlerTab(e, 'hotel')}
                        >
                        {translate('hotels', language) }
                    </span>                         */}
                </div>
            </nav>
            <div>
                <div style = {tabAvia.showIndex}>                    
                    <AviaContainer/> 
                </div>
                {/* <div style = {tabHotel.showIndex}>
                    <HotelContainer/> 
                </div>                   */}
            </div>        
        </div>
    )
}
